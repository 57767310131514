import { sortBy, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { getUserTimezone } from '@commons/utils/date';

import { getTranslatedOrderAnomalies } from '@orders/OrderList/components/OrderForm/constants/anomalies';

export default (productOrders) => {
  const userTimezone = getUserTimezone();

  const orderAnomalies = getTranslatedOrderAnomalies();

  const formattedProductOrders = productOrders.map((productOrder) => {
    const formattedTotPackagingWeight =
      productOrder.supplierProduct.packagingWeight * productOrder.ordered.quantity || '';

    const order = productOrder.order;

    order.orderDate = moment.tz(productOrder.order.orderDate, userTimezone).format('L');
    order.deliveryDate = moment
      .tz(productOrder.order.startOrderDate, productOrder.store.timezone)
      .format('L');

    const formattedReceived = isEmpty(productOrder.received)
      ? productOrder.ordered
      : productOrder.received;

    const formattedInvoiced = isEmpty(productOrder.invoiced)
      ? productOrder.ordered
      : productOrder.invoiced;

    return {
      ...productOrder,
      order,
      received: formattedReceived,
      invoiced: formattedInvoiced,
      totPackagingWeight: formattedTotPackagingWeight,
      anomaly: orderAnomalies[productOrder.received.anomaly],
      supplierProduct: {
        ...productOrder.supplierProduct,
        name: productOrder.supplierProduct.name.trim(),
        category: productOrder.supplierProduct.category || i18next.t('GENERAL.OTHER'),
        subCategory: productOrder.supplierProduct.subCategory || i18next.t('GENERAL.OTHER'),
      },
    };
  });

  return sortBy(formattedProductOrders, ['orderDate']).reverse();
};
