import i18next from 'i18next';

import { CATEGORY_TYPES_OBJECT } from '@commons/constants/categoryTypes';

export const ENTITY_TYPES_CHOICES = [
  { key: 'RECIPES', name: i18next.t('FEATURE.ADMIN.RECIPES') },
  { key: 'INGREDIENTS', name: i18next.t('FEATURE.ADMIN.INGREDIENTS') },
];

export const ENTITY_TYPES_TO_CATEGORY_TYPES = {
  RECIPES: CATEGORY_TYPES_OBJECT.RECIPE,
  INGREDIENTS: CATEGORY_TYPES_OBJECT.INGREDIENT,
};
