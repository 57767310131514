import { connect } from 'react-redux';
import { get, isEmpty, head, uniq, set } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { CATEGORY_TYPES_OBJECT } from '@commons/constants/categoryTypes';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import {
  ENUM_CUSTOM_FILTERS,
  getColumnsAdvancedFilters,
  getCustomFilterIsStrategic,
  getCustomFilterSingleCategory,
  getCustomFilterSingleSubCategory,
  getCustomFilterMultipleStatuses,
} from '@commons/utils/filtersFetches';
import { ExportButton } from '@commons/ExportButton';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getFormattedCurrencyName } from '@commons/utils/translations';
import { getPropertyNoneValue } from '@commons/constants/categoryTypes';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { NestedList, Tooltip } from '@commons/utils/styledLibraryComponents';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import DisplayNumber from '@commons/DisplayNumber';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { supplier as supplierService } from '@services/supplier';
import clientService from '@services/client';
import orderService from '@services/order';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';

import ProductionAnalyticsToggle from '@src/routes/prod/components/AnalyticsContainer/components/ProductionAnalyticsToggle';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import {
  Container,
  ContentContainer,
  FlexedGapContainer,
  SidePaddedGapContainer,
  TurnoverContainer,
  InfoBoxLabel,
} from './styledComponents';
import { exportOrderSPAnalyticsData } from './export';
import { getListHeaders, METRICS, METRIC_KEYS } from './config';

const OrderAnalyticsSupplierProducts = (props) => {
  const {
    client: { clientId, storeName },
    match,
    user,
    stores,
    showErrorMessage,
    currency,
  } = props;

  const path = get(match, 'path');
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const userTimezone = getUserTimezone();

  const yesterday = moment.tz(userTimezone).subtract(1, 'days').endOf('day');
  const maxPastDate = moment.tz(userTimezone).subtract(1, 'year').startOf('day');

  const [selectedMetric, setSelectedMetric] = useState(head(METRICS));

  const [isLoading, setIsLoading] = useState(true);

  const [headers, setHeaders] = useState(getListHeaders(selectedMetric));
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState([]);
  const [turnover, setTurnover] = useState(null);

  /** Filters **/
  const [categoriesToChoose, setCategoriesToChoose] = useState([]);
  const [subCategoriesToChoose, setSubCategoriesToChoose] = useState([]);

  const [suppliers, setSuppliers] = useState([]);
  const [storesWithSuppliers, setStoresWithSuppliers] = useState([]);

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [isStrategic, setIsStrategic] = useState(true);
  const [statuses, setStatuses] = useState(CHOICES_DROPDOWN_ACTIVE);

  const periodPickerState = usePeriodDatePickerState(
    moment.tz(yesterday, userTimezone).subtract(30, 'days').startOf('day'),
    yesterday,
    userTimezone,
  );

  const customFilterMultipleStatuses = getCustomFilterMultipleStatuses(statuses, setStatuses);
  const customFilterIsStrategic = getCustomFilterIsStrategic(isStrategic, setIsStrategic);
  const customFilterSingleCategory = getCustomFilterSingleCategory(
    categoriesToChoose,
    selectedCategory,
    setSelectedCategory,
  );
  const customFilterSingleSubCategory = getCustomFilterSingleSubCategory(
    subCategoriesToChoose,
    selectedSubCategory,
    setSelectedSubCategory,
  );

  useEffect(() => {
    if (isEmpty(stores)) {
      return;
    }

    const clientActiveStoreIds = stores.map(({ id }) => id);

    (async function loadData() {
      const clientSuppliers = await supplierService.getSuppliersOfClient(clientId);

      const { storeIdsWithSuppliers, suppliersWithStoresMapping } = clientSuppliers.reduce(
        (result, supplier) => {
          const { storesMapped } = supplier;

          if (storesMapped.length) {
            result.suppliersWithStoresMapping.push(supplier);

            const mappedStoreIds = storesMapped.map(({ storeId }) => storeId);

            result.storeIdsWithSuppliers = result.storeIdsWithSuppliers.concat(mappedStoreIds);
          }

          return result;
        },
        { storeIdsWithSuppliers: [], suppliersWithStoresMapping: [] },
      );

      const activeStoreIdsWithSuppliers = uniq(storeIdsWithSuppliers).filter((storeId) =>
        clientActiveStoreIds.includes(storeId),
      );

      const storesWithSuppliers = stores.filter(({ id }) =>
        activeStoreIdsWithSuppliers.includes(id),
      );

      const { categories, subCategories } = await clientService.getCategoriesAndSubCategories(
        clientId,
        CATEGORY_TYPES_OBJECT.SUPPLIER_PRODUCT,
      );

      const formattedCategories = categories.map((category) => ({
        id: category.id,
        name: category.name || getPropertyNoneValue(),
      }));

      const formattedSubCategories = subCategories.map((subCategory) => ({
        id: subCategory.id,
        name: subCategory.name || getPropertyNoneValue(),
      }));

      setCategoriesToChoose(formattedCategories);
      setSubCategoriesToChoose(formattedSubCategories);

      setSuppliers(suppliersWithStoresMapping);
      setSelectedSuppliers(suppliersWithStoresMapping);

      setStoresWithSuppliers(storesWithSuppliers);
      setSelectedStores(storesWithSuppliers);

      const updatedFilters = {
        stores: {
          activeList: storesWithSuppliers,
          list: storesWithSuppliers,
          selectedStores: storesWithSuppliers,
        },
        suppliers: !isEmpty(suppliersWithStoresMapping)
          ? {
              list: suppliersWithStoresMapping,
              activeList: suppliersWithStoresMapping,
              selectedSuppliers: suppliersWithStoresMapping,
            }
          : null,
        [ENUM_CUSTOM_FILTERS.TOGGLE_IS_STRATEGIC]: {
          ...customFilterIsStrategic,
        },
        [ENUM_CUSTOM_FILTERS.MULTIPLE_SELECT_STATUSES]: {
          ...customFilterMultipleStatuses,
        },
        [ENUM_CUSTOM_FILTERS.SINGLE_SELECT_CATEGORY]: !isEmpty(formattedCategories)
          ? {
              ...set(customFilterSingleCategory, 'list', [...formattedCategories]),
            }
          : null,
        [ENUM_CUSTOM_FILTERS.SINGLE_SELECT_SUB_CATEGORY]: !isEmpty(formattedSubCategories)
          ? {
              ...set(customFilterSingleSubCategory, 'list', [...formattedSubCategories]),
            }
          : null,
      };

      setFilters(updatedFilters);
      setApplyFilters(true);
    })();
  }, [stores]);

  useEffect(() => {
    if (applyFilters) {
      reloadData();
    }
  }, [applyFilters]);

  useEffect(() => {
    if (periodPickerState.startDate && periodPickerState.endDate) {
      reloadData();
    }
  }, [periodPickerState.startDate, periodPickerState.endDate]);

  useEffect(() => {
    setHeaders(getListHeaders(selectedMetric));

    if (selectedMetric.key === METRIC_KEYS.CURRENCY) {
      const total = makeTotalRow(data);

      setTotalRow(total);
    } else {
      setTotalRow([]);
    }
  }, [data, selectedMetric]);

  const makeTotalRow = (data) => {
    const total = data.reduce(
      (totals, productAnalytics) => {
        totals.orderedVolume += productAnalytics.orderedVolume.inCurrency;
        totals.invoicedVolume += productAnalytics.invoicedVolume.inCurrency;
        totals.receivedVolume += productAnalytics.receivedVolume.inCurrency;
        totals.receivedDiff += productAnalytics.receivedDiff.inCurrency;
        return totals;
      },
      {
        orderedVolume: 0,
        invoicedVolume: 0,
        receivedVolume: 0,
        receivedDiff: 0,
      },
    );

    const totalServiceRate = total.orderedVolume
      ? (total.receivedVolume / total.orderedVolume) * 100
      : null;

    return [
      {
        name: i18next.t('GENERAL.ANALYTICS_LABEL_TOTAL'),
        supplier: { name: '- ' },
        orderedVolume: { inCurrency: total.orderedVolume },
        invoicedVolume: { inCurrency: total.invoicedVolume },
        receivedVolume: { inCurrency: total.receivedVolume },
        receivedDiff: { inCurrency: total.receivedDiff },
        serviceRate: totalServiceRate,
      },
    ];
  };

  const reloadData = () => {
    getAnalyticsData();
    getTurnoverData();
  };

  const getTurnoverData = async () => {
    const startDate = moment
      .tz(periodPickerState.startDate, userTimezone)
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    const endDate = moment
      .tz(periodPickerState.endDate, userTimezone)
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    const selectedStoresIds = selectedStores.map(({ id }) => id);

    try {
      const turnoverByStoreId = await orderService.getTurnoverByStoreIdsAndDates(
        selectedStoresIds,
        startDate,
        endDate,
      );
      setTurnover(<DisplayNumber number={turnoverByStoreId['TOTAL']} withoutDecimals={true} />);
    } catch {
      setTurnover('-');
    }
  };

  const getAnalyticsData = async () => {
    if (isEmpty(stores) || isEmpty(suppliers)) {
      return;
    }
    setIsLoading(true);

    try {
      const categoryIds = selectedCategory && !isStrategic ? [selectedCategory.id] : null;
      const subCategoryIds = selectedSubCategory && !isStrategic ? [selectedSubCategory.id] : null;

      const selectedSuppliersIds = selectedSuppliers.map((supplier) => supplier.id);
      const startDate = moment
        .tz(periodPickerState.startDate, userTimezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
      const endDate = moment
        .tz(periodPickerState.endDate, userTimezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
      const selectedStoresIds = selectedStores.map(({ id }) => id);

      const analytics = await orderService.getOrdersAnalyticsBySupplierProduct(
        clientId,
        startDate,
        endDate,
        selectedStoresIds,
        selectedSuppliersIds,
        isStrategic,
        categoryIds,
        subCategoryIds,
      );

      const statusesFilter = statuses.map((status) => status.filterValue);

      const filteredByStatus = analytics.reduce((result, analytic) => {
        const status = get(analytic, 'supplierProduct.active', false);

        if (!statusesFilter.includes(status)) {
          return result;
        }

        const formattedAnalytics = {
          ...analytic,
          supplierProduct: {
            ...analytic.supplierProduct,
            category: analytic.supplierProduct.category || getPropertyNoneValue(),
            subCategory: analytic.supplierProduct.subCategory || getPropertyNoneValue(),
          },
        };

        result.push(formattedAnalytics);

        return result;
      }, []);

      // No advanced filter to take into consideration, then early return
      if (isEmpty(advancedFilters)) {
        setData(filteredByStatus);

        return;
      }

      const filteredDataWithAdvancedFilters = advancedFilters.reduce(
        (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
        filteredByStatus,
      );

      setData(filteredDataWithAdvancedFilters);
    } catch {
      setData([]);
      showErrorMessage(i18next.t('ORDERS.BY_SUPPLIER_PRODUCT.FETCH_ANALYTICS_FAILURE'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = () => {
    const totalRowData = head(totalRow) || {};

    const dateRange = {
      startDate: moment
        .tz(periodPickerState.startDate, userTimezone)
        .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
      endDate: moment
        .tz(periodPickerState.endDate, userTimezone)
        .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
    };

    const storeTranslations = {
      plural: getClientStoreNameTranslation(storeName, true),
      single: getClientStoreNameTranslation(storeName, false),
    };

    exportOrderSPAnalyticsData(
      data,
      totalRowData,
      {
        dateRange,
        selectedStores,
        selectedSuppliers,
        isStrategic,
        selectedCategory,
        selectedSubCategory,
        statuses,
      },
      storeTranslations,
      currency,
    );
  };

  useEffect(() => {
    const total = makeTotalRow(data);

    setTotalRow(total);
  }, [data]);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <SidePaddedGapContainer>
          <LeftRightSplitter
            left={
              <FlexedGapContainer>
                <div>
                  <DeepsightFiltersButton
                    advancedFilters={advancedFilters}
                    columnsFilterList={getColumnsAdvancedFilters(headers)}
                    customMultipleDropDowns={[customFilterMultipleStatuses]}
                    customSingleDropDowns={[
                      customFilterSingleCategory,
                      customFilterSingleSubCategory,
                    ]}
                    customToggles={[customFilterIsStrategic]}
                    filters={filters}
                    readOnly={isLoading}
                    selectedStores={selectedStores}
                    selectedSuppliers={selectedSuppliers}
                    setAdvancedFilters={setAdvancedFilters}
                    setApplyFilters={setApplyFilters}
                    setFilters={setFilters}
                    setSelectedStores={setSelectedStores}
                    setSelectedSuppliers={setSelectedSuppliers}
                    stores={storesWithSuppliers}
                    style={{ width: 'unset !important' }}
                    suppliers={suppliers}
                    textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
                  />
                </div>
                <PeriodDatePicker
                  disabled={isLoading}
                  endDate={periodPickerState.endDate}
                  focusedDateInput={periodPickerState.focusedDateInput}
                  maxFutureDate={yesterday}
                  maxPastDate={maxPastDate}
                  setFocusedDateInput={periodPickerState.setFocusedDateInput}
                  startDate={periodPickerState.startDate}
                  timezone={userTimezone}
                  onDatesChange={periodPickerState.handleSelectedDates}
                />
                <ProductionAnalyticsToggle
                  choices={METRICS}
                  isDisabled={isLoading}
                  selectedChoice={selectedMetric}
                  setSelectedChoice={setSelectedMetric}
                />
                <TurnoverContainer>
                  <InfoBoxLabel>
                    {i18next.t('GENERAL.METRIC_TURNOVER_EX_TAX', {
                      currencyCode: currency.alphabeticCode,
                    })}
                    <Tooltip
                      text={i18next.t('GENERAL.METRIC_TURNOVER_EX_TAX_TOOLTIP', {
                        currencyName: getFormattedCurrencyName(currency.shortenedName, true),
                      })}
                    />
                  </InfoBoxLabel>

                  <div className="kpi">
                    {isLoading ? <DeepsightComponentLoader height={16} width={16} /> : turnover}
                  </div>
                </TurnoverContainer>
              </FlexedGapContainer>
            }
            right={
              <ExportButton
                handleClick={handleExport}
                isDisabled={isLoading}
                isInpulseOnly={true}
              />
            }
          />
        </SidePaddedGapContainer>

        <NestedList
          data={data}
          defaultOrderBy={`orderedVolume.inCurrency`}
          defaultOrderType={'desc'}
          fixedRowsData={totalRow}
          headers={headers}
          isExpandable={false}
          isLoading={isLoading}
          labelEmptyState={i18next.t('GENERAL.NO_RESULT')}
          languageCode={userLanguageCode}
          minWidth={'978px'}
        />
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalyticsSupplierProducts);
