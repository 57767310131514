import { connect } from 'react-redux';
import { difference, get, groupBy, isEmpty, keyBy, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

import {
  initStockFormValues,
  resetStockFormValues,
  stockFormUpdateRecipeValue,
  stockFormUpdateValue,
} from '@actions/stockForm';
import { loading, loadingSuccess } from '@actions/loading';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DEFAULT_FULL_INVENTORY_TEMPLATE } from '@commons/constants/dropdown';
import { GenericModalContainer } from '@commons/Modals/GenericModal/styledComponents';
import { getFactorToConvertMetricToEntityUnit } from '@commons/utils/conversion';
import { getIsCentralMode } from '@commons/utils/localStorage';
import { getPropertyNoneValue } from '@commons/constants/categoryTypes';
import { handleAppRefresh } from '@commons/utils/refreshApp';
import GenericModal from '@commons/Modals/GenericModal';
import mixpanelUtils, { ENUM_EVENTS } from '@commons/utils/mixpanel';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { canCreateInventory, canEditInventory } from '@selectors/actions/inventoriesStockActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getCentralKitchenStores, getSalesPointStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

import centralKitchenService from '@services/central';
import clientService from '@services/client';
import recipeService from '@services/recipe';
import storageAreaService from '@services/storageArea';

import { RenderValidateModal } from '@orders/OrderList/components/OrderForm/OrderFormModals';

import { ENTITY_TYPES_CHOICES } from '@stocks/utils/constants';
import { INVENTORY_TYPE } from '@stocks/StocksInventories/common/constants';
import { isEditionAllowed } from '@stocks/StocksInventories/common/rights';

import { Container, Content, FilterAndExportContainer } from './styledComponents';
import { ERROR_FORM_TYPES } from './constants/errors';
import { getDownloadInventorySheetWarningModal } from './components/StockFormActions/rendererPdf/modalConfiguration';
import { STOCK_DATA_TYPES } from './constants/types';
import { STOCKFORM_EMPTY_STATES } from './utils/emptyStates';
import { STORAGE_AREA_NONE_ID } from './constants/storageAreaIdNone';
import Header from './components/Header';
import LoadingState from './components/LoadingState';
import RecipeInventoryFormList from './components/RecipeInventoryFormList';
import services from './services';
import StockContent from './components/Content';
import StockFormActions, { generatePDFBlob } from './components/StockFormActions';
import StockFormFilters from './components/Filters';
import StorageAreaListing from './components/StorageAreasListing';
import utils from './utils';

const INTERVAL_CHECK_UPDATED_AT_IN_MS = 30 * 1000; // 30 seconds

const DEFAULT_SELECTED_FILTER = 'subCategory';

function StockForm(props) {
  const {
    user,
    client: { clientId, clientName, hasMultipleBrands },
    params,
    stores,
    closeModal,
    showMessage,
    pageLoaded,
    pageLoading,
    shouldReloadApp,
    authorizedActions,
    totByStorageAreaIdSPIdAndPackagingId,
    params: { isCentralKitchenView, inventoryValidation },
    totByStorageAreaIdAndRecipeId,
    // offline
    isUserOffline,
    isConnectionSlow,
  } = props;
  const [readOnly, setReadOnly] = useState(false);

  const [reference, setReference] = useState('');
  const [stockDate, setStockDate] = useState(null);
  const [isEditingDate, setIsEditingDate] = useState(false);
  const [isCorrectingInventory, setIsCorrectingInventory] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const [partnerId, setPartnerId] = useState();
  const [selectedStore, setSelectedStore] = useState({});
  const [inventoryListId, setInventoryListId] = useState('');
  const [currentInventoryUpdatedAt, setCurrentInventoryUpdatedAt] = useState(null);
  const [isInventoryOutdated, setIsInventoryOutdated] = useState(false);

  /** Inventory template **/
  const [clientILTs, setClientILTs] = useState([]);
  const [dataIdsByILTid, setDataIdsByILTid] = useState({
    [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: {},
    [STOCK_DATA_TYPES.RECIPE]: {},
  });
  const [selectedInventoryListTemplate, setSelectedInventoryListTemplate] = useState(
    DEFAULT_FULL_INVENTORY_TEMPLATE,
  );

  const [selectedMetric, setSelectedMetric] = useState(ENTITY_TYPES_CHOICES[0]);

  const [searchInput, setSearchInput] = useState('');
  const [generatingData, setGeneratingData] = useState(false);

  const [supplierProducts, setSupplierProducts] = useState([]);
  const [categoriesToUnfold, setCategoriesToUnfold] = useState([]);

  const [suppliers, setSuppliers] = useState(null);
  const [inventories, setInventories] = useState({
    supplierProductInventories: null,
    recipeInventories: null,
  });
  const [storeSupplierProductMappings, setStoreSupplierProductMappings] = useState(null);

  // Contains intermediate recipes of client
  const [fetchedRecipes, setFetchedRecipes] = useState(null);
  const [recipes, setRecipes] = useState(null);
  const [recipesByStorageAreaIds, setRecipesByStorageAreaIds] = useState({});
  const [initialRecipesByStorageAreaIds, setInitialRecipesByStorageAreaIds] = useState({});

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [displayAlreadyExistsModal, setDisplayAlreadyExistsModal] = useState(false);

  const [warningModalParams, setWarningModalParams] = useState(null);
  const [infoModalDownloadInventorySheet, setInfoModalDownloadInventorySheet] = useState(false);
  const [isDownloadingInventorySheet, setIsDownloadingInventorySheet] = useState(false);

  const [emptyStateToDisplay, setEmptyStateToDisplay] = useState(STOCKFORM_EMPTY_STATES.NO_DATE);

  const [supplierChoices, setSupplierChoices] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  /*******************/
  /** Storage areas **/
  /*******************/

  // The list of storage areas that should be displayed in the form, depending on the selected type
  const [formStorageAreas, setFormStorageAreas] = useState({
    [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: [],
    [STOCK_DATA_TYPES.RECIPE]: [],
  });
  const [initialStorageAreas, setInitialStorageAreas] = useState([]);
  const [selectedStorageArea, setSelectedStorageArea] = useState({});
  const [spByStorageAreaIds, setSpByStorageAreaIds] = useState({});
  const [initialSPByStorageAreaIds, setInitialSPByStorageAreaIds] = useState({});

  const contentRef = useRef();
  const isCreation = !get(params, 'inventory.id');

  useEffect(() => {
    const { inventory } = params;

    if (get(inventory, 'id', null) && get(selectedStore, 'id', null)) {
      const interval = setInterval(() => {
        checkInventoryLastUpdatedAt(inventory.id, selectedStore.id);
      }, INTERVAL_CHECK_UPDATED_AT_IN_MS);

      return () => clearInterval(interval);
    }
  }, [selectedStore]);

  useEffect(() => {
    if (params.isDateToday && generatingData) {
      handleSetToUpdatable();
    }
  }, [params.isDateToday, generatingData]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!!clientId) {
      (async function loadSupplierOfClient() {
        const result = await services.getSuppliersOfClient(clientId);

        if (!result) {
          showMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_SUPPLIERS_FAILURE'), 'error');
        }

        setSuppliers(result);
      })();

      (async function loadInventoryListTemplatesOfClient() {
        const result = await clientService.getInventoryListTemplates(clientId, {
          withMappings: true,
          filterByUserCatalog: false,
        });

        if (!result) {
          showMessage(
            i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_INVENTORY_LIST_TEMPLATES_ERROR'),
            'error',
          );
          return;
        }

        if (isEmpty(result)) {
          /**
           * No InventoryListTemplate configured on client, i.e:
           * - leave dropdown disabled
           * - no need to have the default "full inventory" option
           * - no need to group SupplierProducts by their mapped template
           */
          return;
        }

        const clientILTs = result.map(({ id, name }) => ({ id, name }));
        const { SPidsKeyByILTid, recipeIdsKeyByILTid } = result.reduce(
          (acc, iltWithSPs) => {
            acc.SPidsKeyByILTid[iltWithSPs.id] = iltWithSPs.supplierProducts.map(({ id }) => id);

            acc.recipeIdsKeyByILTid[iltWithSPs.id] = iltWithSPs.entities.map(({ id }) => id);
            return acc;
          },
          {
            SPidsKeyByILTid: {},
            recipeIdsKeyByILTid: {},
          },
        );

        setClientILTs([DEFAULT_FULL_INVENTORY_TEMPLATE, ...clientILTs]);
        setDataIdsByILTid({
          [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: SPidsKeyByILTid,
          [STOCK_DATA_TYPES.RECIPE]: recipeIdsKeyByILTid,
        });

        const templateIdOfInventory = get(params, 'inventory.inventoryListTemplateId', false);

        if (templateIdOfInventory) {
          const matchingILT =
            clientILTs.find(({ id }) => id === templateIdOfInventory) ||
            DEFAULT_FULL_INVENTORY_TEMPLATE;

          setSelectedInventoryListTemplate(matchingILT);
          return;
        }

        setSelectedInventoryListTemplate(DEFAULT_FULL_INVENTORY_TEMPLATE);
      })();
    }
  }, [user]);

  const checkInventoryLastUpdatedAt = async (inventoryListId) => {
    try {
      const lastUpdatedAtOfInventory = await utils.getInventoryLastUpdatedAt(
        selectedStore.id,
        inventoryListId,
      );

      const isInventoryOutdated = currentInventoryUpdatedAt !== lastUpdatedAtOfInventory;

      if (isInventoryOutdated) {
        setIsInventoryOutdated(true);

        const translatedDatetimeFormat = `DD/MM/YYYY ${i18next
          .t('GENERAL.AT')
          .toLowerCase()} HH:mm`;

        showMessage(
          i18next.t('STOCKS.STOCKS.FORM_OUTDATED_INVENTORY_ERROR_MESSAGE', {
            datetime: moment(lastUpdatedAtOfInventory).format(translatedDatetimeFormat),
          }),
          'error',
          true,
        );
      }
    } catch {} // silently ignore if call catched an error because user doesn't need to know it
  };

  const _getStorageAreasAssociatedToItems = (items) =>
    items.reduce((acc, { storageAreaId }) => {
      if (!!storageAreaId && !acc.has(storageAreaId)) {
        acc.add(storageAreaId);
      }

      return acc;
    }, new Set());

  const _formatItemsByStorageAreaIds = (items) =>
    items.reduce((acc, item) => {
      if (!item.storageAreaId) {
        if (!acc.none) {
          acc.none = [];
        }

        acc.none.push(item);
        return acc;
      }

      if (!acc[item.storageAreaId]) {
        acc[item.storageAreaId] = [];
      }

      acc[item.storageAreaId].push(item);
      return acc;
    }, {});

  const _handleStorageAreasReadOnly = (supplierProducts, recipes, storageAreas) => {
    // sa stands for StorageArea
    const saIdsAssociatedToSPs = _getStorageAreasAssociatedToItems(supplierProducts);
    const saIdsAssociatedToRecipes = _getStorageAreasAssociatedToItems(recipes);

    const formattedSPsByStorageAreaIds = _formatItemsByStorageAreaIds(supplierProducts);
    const formattedRecipesByStorageAreaIds = _formatItemsByStorageAreaIds(recipes);

    const storageAreaNone = {
      id: STORAGE_AREA_NONE_ID,
      name: i18next.t('GENERAL.SELECT_NONE_FEMININE'),
    };

    const filteredSPSAs = storageAreas.filter(({ id }) => saIdsAssociatedToSPs.has(id));
    const filteredRecipeSAs = storageAreas.filter(({ id }) => saIdsAssociatedToRecipes.has(id));

    const formattedSPSAs = filteredSPSAs.map((storageArea) => ({
      ...storageArea,
      count: formattedSPsByStorageAreaIds[storageArea.id].length,
    }));
    const formattedRecipeSAs = filteredRecipeSAs.map((storageArea) => ({
      ...storageArea,
      count: formattedRecipesByStorageAreaIds[storageArea.id].length,
    }));

    const sortedSPSAs = sortBy(formattedSPSAs, 'name');
    const sortedRecipeSAs = sortBy(formattedRecipeSAs, 'name');

    if (formattedSPsByStorageAreaIds[STORAGE_AREA_NONE_ID]) {
      sortedSPSAs.push({
        ...storageAreaNone,
        count: formattedSPsByStorageAreaIds[STORAGE_AREA_NONE_ID].length,
      });
    }
    if (formattedRecipesByStorageAreaIds[STORAGE_AREA_NONE_ID]) {
      sortedRecipeSAs.push({
        ...storageAreaNone,
        count: formattedRecipesByStorageAreaIds[STORAGE_AREA_NONE_ID].length,
      });
    }

    const storageAreasOfSPsAndRecipes = {
      [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: sortedSPSAs,
      [STOCK_DATA_TYPES.RECIPE]: sortedRecipeSAs,
    };

    setFormStorageAreas(storageAreasOfSPsAndRecipes);
    setInitialStorageAreas(storageAreasOfSPsAndRecipes);

    setSpByStorageAreaIds(formattedSPsByStorageAreaIds);
    setInitialSPByStorageAreaIds(formattedSPsByStorageAreaIds);

    setRecipesByStorageAreaIds(formattedRecipesByStorageAreaIds);
    setInitialRecipesByStorageAreaIds(formattedRecipesByStorageAreaIds);

    setSelectedStorageArea(sortedSPSAs[0]);

    return {
      spBySAIds: formattedSPsByStorageAreaIds,
      recipesBySAIds: formattedRecipesByStorageAreaIds,
    };
  };

  const loadStorageAreasForCreation = async (supplierProducts, recipes) => {
    const supplierProductIds = supplierProducts.map(({ id }) => id);
    const recipeIds = recipes.map(({ id }) => id);

    const storageAreas = await storageAreaService.getStorageAreasBySupplierProductAndEntityIds(
      clientId,
      supplierProductIds,
      recipeIds,
    );

    const { spBySAIds, recipesBySAIds } = formatSupplierProductsAndRecipesByStorageArea(
      supplierProducts,
      recipes,
      storageAreas,
    );

    /**
     * Refresh recipe entries
     * Handles the use case where we already in recipes metric and we change inventory list template
     */
    setRecipesByStorageAreaIds(recipesBySAIds);
    setInitialRecipesByStorageAreaIds(recipesBySAIds);

    return { spBySAIds, recipesBySAIds };
  };

  const _formatStorageAreasForEdition = (
    spAlreadyAssociated,
    recipesAlreadyAssociated,
    storageAreas,
  ) =>
    storageAreas.reduce((acc, storageArea) => {
      const spIdsForCurrentStorageArea = spAlreadyAssociated.reduce(
        (result, { id, storageAreaId }) => {
          if (storageAreaId === storageArea.id) {
            result.push(id);
          }

          return result;
        },
        [],
      );

      const recipesIdsForCurrentStorageArea = recipesAlreadyAssociated.reduce(
        (result, { id, storageAreaId }) => {
          if (storageAreaId === storageArea.id) {
            result.push(id);
          }

          return result;
        },
        [],
      );

      if (spIdsForCurrentStorageArea.length || recipesIdsForCurrentStorageArea.length) {
        acc.push({
          ...storageArea,
          supplierProductIds: spIdsForCurrentStorageArea,
          entityIds: recipesIdsForCurrentStorageArea,
        });
      }

      return acc;
    }, []);

  const _handlePreviouslyMappedStorageAreas = (
    alreadyMappedStorageArea,
    spAlreadyAssociated,
    recipesAlreadyAssociated,
    clientStorageAreas,
  ) => {
    const alreadyMappedStorageAreaIds = new Set(alreadyMappedStorageArea.map(({ id }) => id));

    const storageAreasPreviouslyMappedToSPs = spAlreadyAssociated.reduce(
      (acc, supplierProduct) => {
        if (
          !alreadyMappedStorageAreaIds.has(supplierProduct.storageAreaId) &&
          !acc.storageAreaIds.has(supplierProduct.storageAreaId)
        ) {
          acc.storageAreaIds.add(supplierProduct.storageAreaId);
          acc.supplierProducts.push(supplierProduct);
        }

        return acc;
      },
      { storageAreaIds: new Set(), supplierProducts: [] },
    );

    const storageAreasPreviouslyMappedToRecipes = recipesAlreadyAssociated.reduce(
      (acc, recipe) => {
        if (
          !alreadyMappedStorageAreaIds.has(recipe.storageAreaId) &&
          !acc.storageAreaIds.has(recipe.storageAreaId)
        ) {
          acc.storageAreaIds.add(recipe.storageAreaId);
          acc.recipes.push(recipe);
        }

        return acc;
      },
      { storageAreaIds: new Set(), recipes: [] },
    );

    if (
      !storageAreasPreviouslyMappedToSPs.storageAreaIds.size &&
      !storageAreasPreviouslyMappedToRecipes.storageAreaIds.size
    ) {
      return [];
    }

    const associatedSAs = clientStorageAreas.filter(
      ({ id }) =>
        storageAreasPreviouslyMappedToSPs.storageAreaIds.has(id) ||
        storageAreasPreviouslyMappedToRecipes.storageAreaIds.has(id),
    );

    const formattedPreviouslyAssociatedSAs = _formatStorageAreasForEdition(
      storageAreasPreviouslyMappedToSPs.supplierProducts,
      storageAreasPreviouslyMappedToRecipes.recipes,
      associatedSAs,
    );

    return formattedPreviouslyAssociatedSAs;
  };

  const loadStorageAreasForEdition = async (supplierProducts, recipes) => {
    const clientStorageAreas = await storageAreaService.getStorageAreasByClientId(clientId, {
      withoutMappings: true,
      getAllStorageAreas: true,
    });

    const spAlreadyAssociated = supplierProducts.reduce((acc, supplierProduct) => {
      if (supplierProduct.storageAreaId) {
        acc.push(supplierProduct);
      }

      return acc;
    }, []);
    const recipesAlreadyAssociated = recipes.reduce((acc, recipe) => {
      if (recipe.storageAreaId) {
        acc.push(recipe);
      }

      return acc;
    }, []);

    if (inventoryListId && !!readOnly) {
      const formattedStorageAreas = _formatStorageAreasForEdition(
        spAlreadyAssociated,
        recipesAlreadyAssociated,
        clientStorageAreas,
      );

      const { spBySAIds, recipesBySAIds } = formatSupplierProductsAndRecipesByStorageArea(
        supplierProducts,
        recipes,
        formattedStorageAreas,
      );

      return { spBySAIds, recipesBySAIds };
    }

    const inactiveStorageAreas = clientStorageAreas.filter(({ isActive }) => !isActive);

    const formattedInactiveStorageAreas = _formatStorageAreasForEdition(
      spAlreadyAssociated,
      recipesAlreadyAssociated,
      inactiveStorageAreas,
    );

    const supplierProductIds = supplierProducts.map(({ id }) => id);
    const recipeIds = recipes.map(({ id }) => id);

    const activeStorageAreasWithMappings =
      await storageAreaService.getStorageAreasBySupplierProductAndEntityIds(
        clientId,
        supplierProductIds,
        recipeIds,
      );

    const alreadyMappedStorageArea = activeStorageAreasWithMappings.concat(
      formattedInactiveStorageAreas,
    );

    // Handles the use case where an inventory is created and all sp or all recipes are disassociated from the storageArea
    const formattedPreviouslyAssociatedSAs = _handlePreviouslyMappedStorageAreas(
      alreadyMappedStorageArea,
      spAlreadyAssociated,
      recipesAlreadyAssociated,
      clientStorageAreas,
    );

    const formattedSPsAndRecipesByStorageArea = formatSupplierProductsAndRecipesByStorageArea(
      supplierProducts,
      recipes,
      alreadyMappedStorageArea.concat(formattedPreviouslyAssociatedSAs),
    );

    // Force refresh recipe entries after click on 'Update stock report'
    const { recipesBySAIds } = formattedSPsAndRecipesByStorageArea;

    setRecipesByStorageAreaIds(recipesBySAIds);
    setInitialRecipesByStorageAreaIds(recipesBySAIds);

    return formattedSPsAndRecipesByStorageArea;
  };

  const handleSPFormattingForCorrection = (storageAreas, supplierProductsGroupedById) =>
    storageAreas.reduce(
      (acc, { id, supplierProductIds }) => {
        acc.byStorageAreaId[id] = supplierProductIds.map((supplierProductId) => {
          const currentGroupedSPs = supplierProductsGroupedById[supplierProductId];

          let formattedTotByPackagingId = {};

          if (currentGroupedSPs.length) {
            for (const packagingId of Object.keys(currentGroupedSPs[0].totByPackagingId)) {
              formattedTotByPackagingId[packagingId] = null;
            }
          }

          const supplierProductWithTotResetToZero = {
            ...currentGroupedSPs[0],
            totByPackagingId: formattedTotByPackagingId,
          };

          /*
            This condition is made to handle the quantity selected for a supplier product that wasn't associated to any storage area
            during the creation of the inventory but have been associated to a storage area in the future. It's used to see the supplier product
            in the 'none' storage area with its selected value and still see it in the new storage area without any associated value
          */
          if (currentGroupedSPs.length === 1 && !currentGroupedSPs[0].storageAreaId) {
            return supplierProductWithTotResetToZero;
          }

          acc.all.add(supplierProductId);

          const supplierProduct = currentGroupedSPs.find(
            ({ storageAreaId }) => storageAreaId === id,
          );

          if (!supplierProduct) {
            const spNotAssociated = currentGroupedSPs.find(({ storageAreaId }) => !storageAreaId);

            if (!!spNotAssociated) {
              /**
               * Do not push multiple times same supplier product in "none" storage area
               * Fix edge case when we have stock for a SP who is in 'none' storage area and another storage area in the same time (possible with action 'Update stock report' and meanwhile the storage areas have changed)
               **/
              const alreadyInNone = acc.none.find(({ id }) => id === spNotAssociated.id);

              if (!alreadyInNone) {
                acc.none.push(spNotAssociated);
              }
            }

            return supplierProductWithTotResetToZero;
          }

          return supplierProduct;
        }, []);

        return acc;
      },
      /*
        All is necessary because it will be used to filter suppliers products to get which ones
        are associated or not to a storage area. The ones that are not associated end up in the 'none' storage area
        ( the 'none' storage area is not stored in database, it is used to inform the user and display a 'none' pill to select )
      */
      { byStorageAreaId: {}, all: new Set(), none: [] },
    );

  const handleSPFormattingForCreationAndEdition = (storageAreas, supplierProductsGroupedById) => {
    const formattedSupplierProducts = storageAreas.reduce(
      (acc, { id, supplierProductIds }) => {
        acc.byStorageAreaId[id] = supplierProductIds.map((supplierProductId) => {
          const currentGroupedSPs = supplierProductsGroupedById[supplierProductId];

          if (currentGroupedSPs.length === 1) {
            acc.all.add(supplierProductId);

            return currentGroupedSPs[0];
          }

          acc.all.add(supplierProductId);

          const supplierProduct = currentGroupedSPs.find(
            ({ storageAreaId }) => storageAreaId === id,
          );

          if (!supplierProduct) {
            return currentGroupedSPs.find(({ storageAreaId }) => !storageAreaId);
          }

          return supplierProduct;
        });

        return acc;
      },
      /*
        All is necessary because it will be used to filter suppliers products to get which ones
        are associated or not to a storage area. The ones that are not associated end up in the 'none' storage area
        ( the 'none' storage area is not stored in database, it is used to inform the user and display a 'none' pill to select )
      */
      { byStorageAreaId: {}, all: new Set() },
    );

    return formattedSupplierProducts;
  };

  /**
   * Return all the supplierProducts or recipes not associated to storage areas
   *
   * @param {Object[]} elements                     - All the supplierProducts or recipes
   * @param {Object[]} elementsStoredByStorageArea  - The supplierProducts or recipes associated to Storage Areas grouped by storage Areas and also the non SA
   * @param {Object[]} elementsBySAIds              - The supplierProducts or recipes associated grouped by storage area ids
   * @param {String} elementsBySAIds                - The type of data to get, supplierProducts or recipes
   *
   * @returns {Object[]} The SP or recipes that are not associated to Storage Areas
   */
  const _getAllElementNotAssociatedToSAs = (
    elements,
    elementsStoredByStorageArea,
    elementsBySAIds,
    type,
  ) => {
    if (!isCorrectingInventory) {
      return elements.filter(({ id }) => !elementsStoredByStorageArea.all.has(id));
    }

    const allAlreadyAssociated = Object.values(elementsBySAIds).reduce((acc, elementBySAId) => {
      elementBySAId.forEach((element) => {
        if (!acc.has(element.id)) {
          acc.add(element.id);
        }
      });

      return acc;
    }, new Set());

    /*
      Get the element (sp or recipe ) that are not associated or the ones that were not associated before the creation of the inventory
      and were associated later to keep the data true to the original inventory
    */
    return elements.filter(({ id, storageAreaId, totByPackagingId, inventoryQuantity }) => {
      if (!allAlreadyAssociated.has(id)) {
        return true;
      }

      if (type === STOCK_DATA_TYPES.RECIPE) {
        return !storageAreaId && inventoryQuantity != null;
      }

      const hasStockAlreadyEntered = Object.values(totByPackagingId).some((tot) => tot != null);

      return !storageAreaId && hasStockAlreadyEntered;
    });
  };

  const formatSupplierProductsAndRecipesByStorageArea = (
    supplierProducts,
    recipes,
    storageAreas,
  ) => {
    const supplierProductsGroupedById = groupBy(supplierProducts, 'id');
    const recipesGroupedById = groupBy(recipes, 'id');

    const storageAreaNone = {
      id: STORAGE_AREA_NONE_ID,
      name: i18next.t('GENERAL.SELECT_NONE_FEMININE'),
    };

    if (!storageAreas.length) {
      const spsByNoneStorageArea = { none: supplierProducts };
      const recipesByNoneStorageArea = { none: recipes };

      const originalSPStorageAreas = [{ ...storageAreaNone, count: supplierProducts.length }];
      const originalRecipesStorageAreas = [{ ...storageAreaNone, count: recipes.length }];

      setFormStorageAreas({
        [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: originalSPStorageAreas,
        [STOCK_DATA_TYPES.RECIPE]: originalRecipesStorageAreas,
      });

      setInitialStorageAreas({
        [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: originalSPStorageAreas,
        [STOCK_DATA_TYPES.RECIPE]: originalRecipesStorageAreas,
      });

      setSpByStorageAreaIds(spsByNoneStorageArea);
      setInitialSPByStorageAreaIds(spsByNoneStorageArea);

      setRecipesByStorageAreaIds(recipesByNoneStorageArea);
      setInitialRecipesByStorageAreaIds(recipesByNoneStorageArea);

      setSelectedStorageArea(storageAreaNone);

      return {
        spBySAIds: spsByNoneStorageArea,
        recipesBySAIds: recipesByNoneStorageArea,
      };
    }

    if (inventoryListId && readOnly) {
      return _handleStorageAreasReadOnly(supplierProducts, recipes, storageAreas);
    }

    const SPsStoredByStorageArea = isCorrectingInventory
      ? handleSPFormattingForCorrection(storageAreas, supplierProductsGroupedById) // Called when +48h and click on 'Update stock report'
      : handleSPFormattingForCreationAndEdition(storageAreas, supplierProductsGroupedById); // Called when -48h

    /*
      Handles the use case where a sp is disassociated from a storage area in edition before 48h
    */
    const SPbySAId = SPsStoredByStorageArea.byStorageAreaId;

    if (!isCreation || !isEmpty(SPbySAId)) {
      const supplierProductWithStorageAreaId = Object.values(supplierProductsGroupedById).reduce(
        (acc, supplierProducts) => {
          supplierProducts.forEach((supplierProduct) => {
            if (supplierProduct.storageAreaId) {
              acc.push(supplierProduct);
            }
          });

          return acc;
        },
        [],
      );

      supplierProductWithStorageAreaId.forEach((supplierproduct) => {
        const spAssociatedToSameSA = SPbySAId[supplierproduct.storageAreaId];

        if (!spAssociatedToSameSA) {
          SPsStoredByStorageArea.byStorageAreaId[supplierproduct.storageAreaId] = [supplierproduct];
          SPsStoredByStorageArea.all.add(supplierproduct.id);

          return;
        }

        const alreadyAssociatedSP = spAssociatedToSameSA.find(
          ({ id }) => id === supplierproduct.id,
        );

        if (!alreadyAssociatedSP) {
          SPsStoredByStorageArea.byStorageAreaId[supplierproduct.storageAreaId].push(
            supplierproduct,
          );
          SPsStoredByStorageArea.all.add(supplierproduct.id);
        }
      });
    }

    SPsStoredByStorageArea.byStorageAreaId.none = _getAllElementNotAssociatedToSAs(
      supplierProducts,
      SPsStoredByStorageArea,
      SPbySAId,
      STOCK_DATA_TYPES.SUPPLIER_PRODUCT,
    );

    const recipesStoredByStorageArea = _storeRecipesByStorageArea(storageAreas, recipesGroupedById);

    /*
      Handles the use case where a recipe is disassociated from a storage area in edition before 48h
    */
    const recipesBySAId = recipesStoredByStorageArea.byStorageAreaId;

    if (!isCreation || !isEmpty(recipesBySAId)) {
      Object.values(recipesGroupedById).forEach((recipes) => {
        recipes.forEach((recipe) => {
          if (!recipe.storageAreaId) {
            return;
          }

          const recipeAssociatedToSameSA = recipesBySAId[recipe.storageAreaId];
          if (!recipeAssociatedToSameSA) {
            recipesStoredByStorageArea.byStorageAreaId[recipe.storageAreaId] = [recipe];
            recipesStoredByStorageArea.all.add(recipe.id);

            return;
          }

          const alreadyAssociatedRecipe = recipeAssociatedToSameSA.find(
            ({ id }) => id === recipe.id,
          );

          if (!alreadyAssociatedRecipe) {
            recipesStoredByStorageArea.byStorageAreaId[recipe.storageAreaId].push(recipe);
            recipesStoredByStorageArea.all.add(recipe.id);
          }
        });
      });
    }

    recipesStoredByStorageArea.byStorageAreaId.none = _getAllElementNotAssociatedToSAs(
      recipes,
      recipesStoredByStorageArea,
      recipesBySAId,
      STOCK_DATA_TYPES.RECIPE,
    );

    const storageAreasWithSPsCount = storageAreas.map((storageArea) => {
      const visibleSPAssociatedToSA = SPsStoredByStorageArea.byStorageAreaId[storageArea.id];

      return {
        ...storageArea,
        count: !!visibleSPAssociatedToSA
          ? visibleSPAssociatedToSA.length
          : storageArea.supplierProductIds.length,
      };
    });
    const storageAreasWithRecipesCount = storageAreas.map((storageArea) => {
      const visibleRecipesAssociatedToSA =
        recipesStoredByStorageArea.byStorageAreaId[storageArea.id];

      return {
        ...storageArea,
        count: !!visibleRecipesAssociatedToSA
          ? visibleRecipesAssociatedToSA.length
          : storageArea.entityIds.length,
      };
    });

    let sortedSPSAs = sortBy(storageAreasWithSPsCount, 'name');
    let sortedRecipeSAs = sortBy(storageAreasWithRecipesCount, 'name');

    const noneSASPCount = Object.keys(SPsStoredByStorageArea.byStorageAreaId.none).length;
    const noneSARecipeCount = Object.keys(recipesStoredByStorageArea.byStorageAreaId.none).length;

    if (noneSASPCount) {
      sortedSPSAs.push({
        ...storageAreaNone,
        count: noneSASPCount,
      });
    }

    if (noneSARecipeCount) {
      sortedRecipeSAs.push({
        ...storageAreaNone,
        count: noneSARecipeCount,
      });
    }

    setFormStorageAreas({
      [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: sortedSPSAs,
      [STOCK_DATA_TYPES.RECIPE]: sortedRecipeSAs,
    });

    setInitialStorageAreas({
      [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: sortedSPSAs,
      [STOCK_DATA_TYPES.RECIPE]: sortedRecipeSAs,
    });

    setSpByStorageAreaIds(SPsStoredByStorageArea.byStorageAreaId);
    setInitialSPByStorageAreaIds(SPsStoredByStorageArea.byStorageAreaId);

    setRecipesByStorageAreaIds(recipesStoredByStorageArea.byStorageAreaId);
    setInitialRecipesByStorageAreaIds(recipesStoredByStorageArea.byStorageAreaId);

    setSelectedStorageArea(sortedSPSAs[0]);

    return {
      spBySAIds: SPsStoredByStorageArea.byStorageAreaId,
      recipesBySAIds: recipesStoredByStorageArea.byStorageAreaId,
    };
  };

  const _storeRecipesByStorageArea = (storageAreas, recipesGroupedById) => {
    if (isCorrectingInventory) {
      const result = storageAreas.reduce(
        (acc, { id, entityIds }) => {
          acc.byStorageAreaId[id] = entityIds.map((entityId) => {
            const currentGroupedRecipes = recipesGroupedById[entityId];

            let inventoryQuantity = null;

            const recipeWithInventoryQuantityResetToZero = {
              ...currentGroupedRecipes[0],
              inventoryQuantity,
            };

            /*
              This condition is made to handle the quantity selected for a recipe that wasn't associated to any storage area
              during the creation of the inventory but has been associated to a storage area afterwards. It's used to see the recipe
              in the 'none' storage area with its selected value and still see it in the new storage area without any associated value
            */
            if (currentGroupedRecipes.length === 1 && !currentGroupedRecipes[0].storageAreaId) {
              return recipeWithInventoryQuantityResetToZero;
            }

            acc.all.add(entityId);

            const recipe = currentGroupedRecipes.find(({ storageAreaId }) => storageAreaId === id);

            if (!recipe) {
              const recipeNotAssociated = currentGroupedRecipes.find(
                ({ storageAreaId }) => !storageAreaId,
              );

              if (!!recipeNotAssociated) {
                const alreadyInNone = acc.none.find(({ id }) => id === recipeNotAssociated.id);

                /**
                 * Do not push multiple times same recipe in "none" storage area
                 * Fix edge case when we have stock for a recipe who is in 'none' storage area and another storage area in the same time (possible with action 'Update stock report' and meanwhile the storage areas have changed)
                 **/
                if (!alreadyInNone) {
                  acc.none.push(recipeNotAssociated);
                }
              }

              return recipeWithInventoryQuantityResetToZero;
            }

            return recipe;
          }, []);

          return acc;
        },
        /*
          All is necessary because it will be used to filter recipes to get which ones
          are associated or not to a storage area. The ones that are not associated end up in the 'none' storage area
          ( the 'none' storage area is not stored in database, it is used to inform the user and display a 'none' pill to select )
        */
        { byStorageAreaId: {}, all: new Set(), none: [] },
      );

      return result;
    }

    const result = storageAreas.reduce(
      (acc, { id, entityIds }) => {
        acc.byStorageAreaId[id] = entityIds.map((entityId) => {
          const currentGroupedRecipes = recipesGroupedById[entityId];

          if (currentGroupedRecipes.length === 1) {
            acc.all.add(entityId);

            return currentGroupedRecipes[0];
          }

          acc.all.add(entityId);

          const recipe = currentGroupedRecipes.find(({ storageAreaId }) => storageAreaId === id);

          if (!recipe) {
            return currentGroupedRecipes.find(({ storageAreaId }) => !storageAreaId);
          }

          return recipe;
        });

        return acc;
      },
      { byStorageAreaId: {}, all: new Set() },
    );

    return result;
  };

  // Retrieve the list of store supplier product mappings
  useEffect(() => {
    if (!selectedStore || !selectedStore.id || !stockDate || isEditingDate) {
      return;
    }

    setGeneratingData(true);

    (async function loadStoreSupplierProductMappings() {
      // TODO - [TIMEZONES] Check if we need to timezone formattedStockDate to get sspm (is used as a sspm startDate)
      const formattedStockDate = moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      const result = await services.getStoreSupplierProductMappingsByStoreId(
        selectedStore.id,
        formattedStockDate,
      );

      if (!result) {
        showMessage(i18next.t('GENERAL.FETCH_SUPPLIER_PRODUCTS_FAILURE'), 'error');

        setStoreSupplierProductMappings([]);

        return;
      }

      const sspmsAvailableForStock = result.filter(({ hasStock }) => !!hasStock);

      setStoreSupplierProductMappings(sspmsAvailableForStock);

      await getRecipes();
    })();
  }, [isEditingDate, selectedStore, stockDate]);

  // If existing inventory, retrieve the list of inventory details
  useEffect(() => {
    if (!selectedStore || !selectedStore.id || !stockDate || !inventoryListId || isEditingDate) {
      return;
    }

    setGeneratingData(true);

    // TODO: See if we can merge logic flow into one state like dataIdsByILTid
    (async function loadInventoriesFromInventoriesListOfStore() {
      const result = await services.getInventoriesFromInventoriesListOfStore(
        selectedStore.id,
        inventoryListId,
      );

      const inventories = {
        supplierProductInventories: null,
        recipeInventories: null,
      };

      if (!result) {
        showMessage(i18next.t('STOCKS.STOCKS.FORM_UNABLE_TO_LOAD_INVENTORY'), 'error');

        setInventories({ ...inventories });

        return;
      }

      const { inventories: receivedInventories, recipeInventories: fetchedRecipeInventories } =
        result;
      const receivedSupplierProducts = await services.getSupplierProductsForInventories(
        selectedStore.id,
        receivedInventories,
        showMessage,
      );

      const recipesWithStocksIds = fetchedRecipeInventories.map(({ entityId }) => entityId);
      const fetchedRecipesWithProducts = recipesWithStocksIds.length
        ? await recipeService.getAssociatedRecipesByIds(recipesWithStocksIds, false)
        : [];
      const fetchedRecipes = fetchedRecipesWithProducts.map(({ recipe }) => recipe);

      if (!receivedSupplierProducts) {
        showErrorMessage(i18next.t('STOCKS.STOCKS.FORM_UNABLE_TO_LOAD_INVENTORY'));
        setInventories({ ...inventories });

        return;
      }

      if (!fetchedRecipes) {
        showErrorMessage(i18next.t('STOCKS.STOCKS.FORM_UNABLE_TO_LOAD_INVENTORY'));
        setInventories({ ...inventories });

        return;
      }

      if (receivedInventories.length === 0 || receivedSupplierProducts.length === 0) {
        inventories.supplierProductInventories = [];
      } else {
        const supplierProductInventoriesKeyById = keyBy(receivedSupplierProducts, 'id');

        const updatedInventories = receivedInventories.reduce((result, inventory) => {
          const matchingSupplierProduct =
            supplierProductInventoriesKeyById[inventory.supplierProductId];

          if (matchingSupplierProduct) {
            result.push({
              ...matchingSupplierProduct,
              tot: inventory.tot,
              price: inventory.price,
              supplierProductId: inventory.supplierProductId,
              packagings: inventory.lnkSupplierproductInventoryrel.packagings,
              supplierProductPackagingId: inventory.supplierProductPackagingId,
              storageAreaId: inventory.storageAreaId,
            });
          }

          return result;
        }, []);

        inventories.supplierProductInventories = updatedInventories;
      }

      if (fetchedRecipeInventories.length === 0 || fetchedRecipes.length === 0) {
        inventories.recipeInventories = [];
      } else {
        const fetchedRecipesKeyById = keyBy(fetchedRecipes, 'id');

        const updatedRecipeInventories = fetchedRecipeInventories.reduce(
          (result, recipeInventory) => {
            const matchingRecipe = fetchedRecipesKeyById[recipeInventory.entityId];

            if (matchingRecipe) {
              const formattedRecipe = {
                ...matchingRecipe,
                cost: recipeInventory.cost,
                inventoryQuantity: recipeInventory.quantity, // avoid mismatching the recipe's defined quantity and a recipeInventory's stock quantity
                storageAreaId: recipeInventory.storageAreaId,
              };

              if (hasMultipleBrands) {
                const { brands } = matchingRecipe;
                const storeBrandId = get(selectedStore, 'brandId', null);

                const foundRecipeWithStoreBrand = brands.find(({ id }) => id === storeBrandId);

                result.push({
                  ...formattedRecipe,
                  brand: get(foundRecipeWithStoreBrand, 'name', null),
                });

                return result;
              }

              result.push(formattedRecipe);
            }

            return result;
          },
          [],
        );

        inventories.recipeInventories = updatedRecipeInventories;
      }

      setInventories({ ...inventories });
    })();
  }, [isEditingDate, selectedStore, stockDate, inventoryListId]);

  // Generate inventory form data
  useEffect(() => {
    (async () => {
      if (
        suppliers &&
        stockDate &&
        storeSupplierProductMappings &&
        fetchedRecipes &&
        (!inventoryListId ||
          (inventoryListId && inventories.supplierProductInventories) ||
          (inventoryListId && inventories.recipeInventories))
      ) {
        setGeneratingData(true);

        const formattedSupplierProducts = utils.formatSupplierProducts({
          readOnly,
          suppliers,
          inventories: inventories.supplierProductInventories,
          inventoryListId,
          storeSupplierProductMappings,
        });
        let SPsToSet = formattedSupplierProducts;

        const formattedRecipes = utils.formatRecipes({
          readOnly,
          recipes: fetchedRecipes,
          inventoryListId,
          recipeInventories: inventories.recipeInventories,
        });
        let recipesToSet = formattedRecipes;

        if (isCreation || !readOnly) {
          // Filtering by template is only necessary when creating a new inventory or editing one

          const templateId =
            get(params, 'inventory.inventoryListTemplateId', false) || // template of existing inventory
            get(selectedInventoryListTemplate, 'id', DEFAULT_FULL_INVENTORY_TEMPLATE.id); // changed selection of new inventory

          if (
            templateId !== DEFAULT_FULL_INVENTORY_TEMPLATE.id &&
            !!dataIdsByILTid[STOCK_DATA_TYPES.SUPPLIER_PRODUCT][templateId]
          ) {
            const SPsOfTemplate = formattedSupplierProducts.filter(
              (sp) =>
                Object.values(sp.totByPackagingId).some((tot) => !!tot) || // keep any reference which has stock entry
                dataIdsByILTid[STOCK_DATA_TYPES.SUPPLIER_PRODUCT][templateId].includes(sp.id), // keep any supplier product which is mapped to the template
            );

            SPsToSet = SPsOfTemplate;

            const recipesOfTemplate = formattedRecipes.filter((recipe) =>
              dataIdsByILTid[STOCK_DATA_TYPES.RECIPE][templateId].includes(recipe.id),
            );

            recipesToSet = recipesOfTemplate;
          }
        }

        setSupplierProducts(SPsToSet);
        setRecipes(recipesToSet);

        _setSuppliersFromSPs(SPsToSet);

        let { spBySAIds, recipesBySAIds } = isCreation
          ? await loadStorageAreasForCreation(SPsToSet, recipesToSet)
          : await loadStorageAreasForEdition(SPsToSet, recipesToSet);

        if (
          isEmpty(totByStorageAreaIdSPIdAndPackagingId) ||
          isEmpty(totByStorageAreaIdAndRecipeId) ||
          isCorrectingInventory
        ) {
          const initialTotByStorageAreaIdSPIdAndPackagingId =
            _generateInitialSPStockFormValues(spBySAIds);
          const initialTotByStorageAreaIdAndRecipeId =
            _generateInitialRecipesStockFormValues(recipesBySAIds);

          props.initStockFormValues(
            initialTotByStorageAreaIdSPIdAndPackagingId,
            initialTotByStorageAreaIdAndRecipeId,
          );
        }

        setGeneratingData(false);
      }
    })();
  }, [
    suppliers,
    stockDate,
    inventories,
    inventoryListId,
    storeSupplierProductMappings,
    readOnly,
    selectedInventoryListTemplate,
    fetchedRecipes,
  ]);

  const _generateInitialSPStockFormValues = (spBySAIds) => {
    const initialTotByStorageAreaIdSPIdAndPackagingId = {};

    for (const [storageAreaId, supplierProducts] of Object.entries(spBySAIds)) {
      initialTotByStorageAreaIdSPIdAndPackagingId[storageAreaId] = supplierProducts.reduce(
        (result, { id, totByPackagingId }) => {
          result[id] = totByPackagingId;

          return result;
        },
        {},
      );
    }

    return initialTotByStorageAreaIdSPIdAndPackagingId;
  };
  const _generateInitialRecipesStockFormValues = (recipesBySAIds) => {
    const initialTotByStorageAreaIdAndRecipeId = {};

    for (const [storageAreaId, recipes] of Object.entries(recipesBySAIds)) {
      initialTotByStorageAreaIdAndRecipeId[storageAreaId] = recipes.reduce(
        (result, { id, inventoryQuantity }) => {
          // if inventoryQuantity is set, it is a RecipeInventory's quantity
          result[id] = inventoryQuantity;

          return result;
        },
        {},
      );
    }
    return initialTotByStorageAreaIdAndRecipeId;
  };

  const _setSuppliersFromSPs = (suppliersProducts) => {
    const suppliersFromSps = suppliersProducts.reduce(
      (acc, { lnkSupplierSupplierproductrel: { id, name } }) => {
        if (!acc[id]) {
          acc[id] = {
            id,
            value: name,
          };
        }

        return acc;
      },
      {},
    );

    const initialSuppliersList = Object.values(suppliersFromSps);

    setSupplierChoices(initialSuppliersList);
    setSelectedSuppliers(initialSuppliersList);
  };

  // Retrieve useful data in params to set form (editor viewing mode)
  useEffect(() => {
    if (!params || !user) {
      return;
    }

    // params.storeId always exist
    const matchingStore = stores.find(({ id }) => params.storeId === id);

    setPartnerId(utils.getPartnerId(stores, params.storeId));
    setSelectedStore(matchingStore || {});

    if (params.inventory) {
      const { inventory } = params;

      if (inventory.id !== undefined && inventory.storeId !== undefined) {
        setInventoryListId(inventory.id);
        setCurrentInventoryUpdatedAt(inventory.updatedAt);

        setReference(inventory.reference);

        setStockDate(moment.tz(inventory.timestamp, matchingStore.timezone));
        handleSetToUpdatable();
      }
    }
  }, [user, params]);

  // Handle search input
  useEffect(() => {
    handleSearchInput(searchInput, selectedMetric);
  }, [initialSPByStorageAreaIds, searchInput]);

  // Handle suppliers filter changes
  useEffect(() => {
    handleSuppliersChange();
  }, [selectedSuppliers]);

  // Check form validity
  useEffect(() => {
    const formattedInventories = formStorageAreas[selectedMetric.key].reduce((acc, { id }) => {
      if (!spByStorageAreaIds[id]) {
        return acc;
      }

      const payload = utils.getInventoryPayload(spByStorageAreaIds[id], {
        listId: inventoryListId,
        totByStorageAreaIdSPIdAndPackagingId: totByStorageAreaIdSPIdAndPackagingId[id],
        storageAreaId: id,
      });

      acc = [...acc, ...payload];
      return acc;
    }, []);

    const formattedRecipeInventories = formStorageAreas[selectedMetric.key].reduce(
      (acc, { id }) => {
        if (!recipesByStorageAreaIds[id]) {
          return acc;
        }

        const payload = utils.getRecipeInventoryPayload(recipesByStorageAreaIds[id], {
          inventoryListId,
          totByRecipeId: totByStorageAreaIdAndRecipeId[id],
          storageAreaId: id,
        });

        acc = [...acc, ...payload];
        return acc;
      },
      [],
    );

    setIsFormDirty(!!formattedInventories.length || !!formattedRecipeInventories.length);
  }, [
    supplierProducts,
    totByStorageAreaIdSPIdAndPackagingId,
    recipes,
    totByStorageAreaIdAndRecipeId,
  ]);

  // Update reference's name
  useEffect(() => {
    setReference(
      utils.getReference(
        readOnly && !isEditingDate,
        get(params, 'inventory.reference'),
        params.inventoryType,
        partnerId,
        stockDate,
        get(selectedStore, 'timezone', null),
      ),
    );
  }, [readOnly, params, partnerId, stockDate, isEditingDate]);

  useEffect(() => {
    handleSetToUpdatable();
  }, [isCorrectingInventory]);

  useEffect(() => {
    if (!!infoModalDownloadInventorySheet) {
      setWarningModalParams(
        getDownloadInventorySheetWarningModal({
          ...props,
          infoModalDownloadInventorySheet,
          setInfoModalDownloadInventorySheet,
          setIsDownloadingInventorySheet,
          generatePDFBlob,
          _handleMixPanelEvent,
        }),
      );
    }

    if (!isDownloadingInventorySheet) {
      setInfoModalDownloadInventorySheet(null);
    }
  }, [infoModalDownloadInventorySheet, isDownloadingInventorySheet]);

  // Handle categories closing on ILT change and emptyState rendering
  useEffect(() => {
    setCategoriesToUnfold([]);

    if (!stockDate) {
      setEmptyStateToDisplay(STOCKFORM_EMPTY_STATES.NO_DATE);
      return;
    }

    setEmptyStateToDisplay(null);
  }, [selectedInventoryListTemplate, spByStorageAreaIds, stockDate, generatingData]);

  useEffect(() => {
    setSelectedStorageArea(formStorageAreas[selectedMetric.key][0]);
  }, [selectedMetric]);

  const _handleMixPanelEvent = () => {
    const mixPanelProperties = {
      clientId,
      clientName,
      storeName: selectedStore.name,
      storeId: selectedStore.id,
      userId: user.id,
      userEmail: user.email,
    };

    mixpanelUtils.sendMetric(ENUM_EVENTS.DOWNLOAD_INVENTORY_SHEET, mixPanelProperties);
  };

  const getRecipes = async () => {
    try {
      const recipes = isCentralKitchenView
        ? await centralKitchenService.getKitchenRecipesOfClient(clientId, true, true)
        : await recipeService.getRecipesOfClient(clientId, {
            computeRecipesCost: true,
            onlyAvailableInStocks: true,
          });

      const storeBrandId = get(selectedStore, 'brandId', null);

      const formattedRecipes = recipes.reduce((acc, recipe) => {
        if (hasMultipleBrands) {
          const foundRecipeWithStoreBrand = recipe.brands.find(({ id }) => id === storeBrandId);

          if (recipe.brands.length && !foundRecipeWithStoreBrand) {
            return acc;
          }

          acc.push({
            ...recipe,
            category: recipe.category || getPropertyNoneValue(),
            brand: get(foundRecipeWithStoreBrand, 'name', null),
            cost: recipe.cost * getFactorToConvertMetricToEntityUnit(recipe.unit),
          });

          return acc;
        }

        acc.push({
          ...recipe,
          category: recipe.category || getPropertyNoneValue(),
          cost: recipe.cost * getFactorToConvertMetricToEntityUnit(recipe.unit),
        });

        return acc;
      }, []);

      setFetchedRecipes(formattedRecipes);
    } catch {
      showErrorMessage(i18next.t('GENERAL.FETCH_INTERMEDIATE_RECIPES_FAILURE'));

      setFetchedRecipes([]);
    }
  };

  const handleCloseStockForm = () => {
    if (params.updateHandle) {
      params.updateHandle();
    }

    closeModal();

    // Necessary to empty form inputs if user tries to create a new inventory
    props.resetStockFormValues();
  };

  const handleClickClose = () => {
    if (isFormDirty) {
      setDisplayConfirmationModal(true);

      return;
    }

    handleCloseStockForm();
  };

  const setSelectedStoreById = (storeId) => {
    const matchingStore = stores.find((store) => store.id === storeId) || {};

    setSelectedStore(matchingStore);
    setPartnerId(matchingStore.partnerId);
    if (!!stockDate) {
      setStockDate(moment.tz(stockDate, matchingStore.timezone));
    }
  };

  const setSelectedTemplateById = (inventoryListTemplateId) => {
    const matchingILT =
      clientILTs.find(({ id }) => id === inventoryListTemplateId) ||
      DEFAULT_FULL_INVENTORY_TEMPLATE;

    setSelectedInventoryListTemplate(matchingILT);
  };

  /**
   * Filter visible SP / Recipes items to match search or/and suppliers filter
   *
   * @param {String} searchInput - Current search input
   * @param {Object} currentlySelectedMetric - Current selected metric (sp or recipe)
   * @param {Function} setItemsByStorageAreaIds - Function called to update SP/Recipes items
   * @param {Object} initialItemsBySAIds - SP/Recipes items to be filtered
   */
  const handleSetItemsAndStorageAreas = (
    searchInput,
    currentlySelectedMetric,
    setItemsByStorageAreaIds,
    initialItemsBySAIds,
  ) => {
    const filteredItemsBySearch = {};
    const filteredStorageAreas = [];
    let storageAreaNone = {};

    for (const [storageAreaId, items] of Object.entries(initialItemsBySAIds)) {
      const filteredItems = items.filter(({ name, lnkSupplierSupplierproductrel }) => {
        const nameValidCondition = !!searchInput
          ? normalizeStringValue(name).includes(normalizeStringValue(searchInput))
          : true;

        if (currentlySelectedMetric.key === STOCK_DATA_TYPES.RECIPE) {
          return nameValidCondition;
        }

        const { id: supplierId } = lnkSupplierSupplierproductrel;

        const supplierValidCondition = selectedSuppliers.some(
          (supplier) => supplier.id === supplierId,
        );

        return nameValidCondition && supplierValidCondition;
      });

      const filteredItemsKeyByIds = keyBy(filteredItems, 'id');

      const associatedEntityIds = new Set(filteredItems.map(({ entityId }) => entityId));

      let filteredItemsWithAssociatedToSameEntities = [];

      if (!!searchInput) {
        filteredItemsWithAssociatedToSameEntities = items.reduce((acc, item) => {
          /*
            The following condition is used to get the items (sp or recipe) associated to the same entities
            as the ones that correspond to the search so we can group them later in the process
          */
          if (
            !filteredItemsKeyByIds[item.id] &&
            !!item.entityId &&
            associatedEntityIds.has(item.entityId)
          ) {
            acc.push(item);
          }

          return acc;
        }, []);
      }

      // all items that correspond to the search for the current storage area
      const allFoundItems = filteredItems.concat(filteredItemsWithAssociatedToSameEntities);

      filteredItemsBySearch[storageAreaId] = allFoundItems;

      const currentStorageArea = formStorageAreas[currentlySelectedMetric.key].find(
        ({ id }) => id === storageAreaId,
      );

      if (!currentStorageArea) {
        continue;
      }

      /*
        We separate the 'none' storage area because we will later push it at the end of the storage areas
        because we always want it to be the last one to be displayed
      */
      if (currentStorageArea.id === STORAGE_AREA_NONE_ID) {
        storageAreaNone = {
          ...currentStorageArea,
          count: allFoundItems.length,
        };

        continue;
      }

      filteredStorageAreas.push({
        ...currentStorageArea,
        count: allFoundItems.length,
      });
    }

    let sortedStorageAreas = sortBy(filteredStorageAreas, 'name');

    if (!isEmpty(storageAreaNone)) {
      sortedStorageAreas = sortedStorageAreas.concat(storageAreaNone);
    }

    setItemsByStorageAreaIds(filteredItemsBySearch);

    // sas stands for storageAreas
    const sasWithCorrespondingItems = sortedStorageAreas.filter(({ count }) => count > 0);

    const selectedSAWithCorrespondingItems = sasWithCorrespondingItems.find(
      ({ id }) => id === selectedStorageArea.id,
    );

    if (
      sasWithCorrespondingItems.length &&
      (!selectedSAWithCorrespondingItems || selectedSAWithCorrespondingItems.count === 0)
    ) {
      setSelectedStorageArea(sasWithCorrespondingItems[0]);
    }

    setFormStorageAreas({ ...formStorageAreas, [currentlySelectedMetric.key]: sortedStorageAreas });
  };

  const filterWithSuppliers = (initialSPByStorageAreaIds) => {
    const selectedSuppliersKeyById = keyBy(selectedSuppliers, 'id');

    const filteredSPs = Object.entries(initialSPByStorageAreaIds).reduce(
      (acc, [storageAreaId, supplierProducts]) => {
        const filteredSPsOfStorageArea = supplierProducts.reduce((acc, supplierProduct) => {
          if (selectedSuppliersKeyById[supplierProduct.supplierId]) {
            acc.push(supplierProduct);
          }

          return acc;
        }, []);

        acc[storageAreaId] = filteredSPsOfStorageArea;

        return acc;
      },
      {},
    );

    return filteredSPs;
  };

  const handleFormStorageAreasWithFilteredItems = (
    filteredItemsByStorageAreaIds,
    currentlySelectedMetric,
  ) => {
    const filteredStorageAreas = [];
    let storageAreaNone = {};

    for (const [storageAreaId, items] of Object.entries(filteredItemsByStorageAreaIds)) {
      const currentStorageArea = formStorageAreas[currentlySelectedMetric.key].find(
        ({ id }) => id === storageAreaId,
      );

      if (!currentStorageArea) {
        continue;
      }

      /*
        We separate the 'none' storage area because we will later push it at the end of the storage areas
        because we always want it to be the last one to be displayed
      */
      if (currentStorageArea.id === STORAGE_AREA_NONE_ID) {
        storageAreaNone = {
          ...currentStorageArea,
          count: items.length,
        };

        continue;
      }

      filteredStorageAreas.push({
        ...currentStorageArea,
        count: items.length,
      });
    }

    let sortedStorageAreas = sortBy(filteredStorageAreas, 'name');

    if (!isEmpty(storageAreaNone)) {
      sortedStorageAreas = sortedStorageAreas.concat(storageAreaNone);
    }

    setFormStorageAreas({ ...formStorageAreas, [currentlySelectedMetric.key]: sortedStorageAreas });
  };

  const handleSearchInput = (searchInput, currentlySelectedMetric) => {
    setIsSearchActive(!!searchInput);

    const filteredInitialSPByStorageAreaIds = filterWithSuppliers(initialSPByStorageAreaIds);

    const settersAndInitialsByMetric = {
      [STOCK_DATA_TYPES.SUPPLIER_PRODUCT]: {
        setItemsByStorageAreaIds: setSpByStorageAreaIds,
        initialItemsBySAIds: filteredInitialSPByStorageAreaIds,
      },
      [STOCK_DATA_TYPES.RECIPE]: {
        setItemsByStorageAreaIds: setRecipesByStorageAreaIds,
        initialItemsBySAIds: initialRecipesByStorageAreaIds,
      },
    };

    const setItemsByStorageAreaIds =
      settersAndInitialsByMetric[currentlySelectedMetric.key].setItemsByStorageAreaIds;

    const initialItemsBySAIds =
      settersAndInitialsByMetric[currentlySelectedMetric.key].initialItemsBySAIds;

    if (!initialItemsBySAIds || isEmpty(initialItemsBySAIds)) {
      setItemsByStorageAreaIds({});

      return;
    }

    if (!searchInput) {
      setItemsByStorageAreaIds(initialItemsBySAIds);

      if (currentlySelectedMetric.key === STOCK_DATA_TYPES.SUPPLIER_PRODUCT) {
        // Keep each SP storage areas count correct with filtered SP
        handleFormStorageAreasWithFilteredItems(
          filteredInitialSPByStorageAreaIds,
          currentlySelectedMetric,
        );

        return;
      }

      setFormStorageAreas({
        ...formStorageAreas,
        [currentlySelectedMetric.key]: initialStorageAreas[currentlySelectedMetric.key],
      });

      return;
    }

    handleSetItemsAndStorageAreas(
      searchInput,
      currentlySelectedMetric,
      setItemsByStorageAreaIds,
      initialItemsBySAIds,
    );

    return;
  };

  const handleSuppliersChange = () => {
    if (!selectedSuppliers.length) {
      return;
    }

    handleSetItemsAndStorageAreas(
      searchInput,
      selectedMetric,
      setSpByStorageAreaIds,
      initialSPByStorageAreaIds,
    );
  };

  const areAllStocksInputsFilled = () =>
    supplierProducts.every(({ id }) => {
      const totByPackagingId = totByStorageAreaIdSPIdAndPackagingId[id] || {};

      return Object.values(totByPackagingId).some((value) => !!value || value === 0);
    });

  const isFormValid = () => {
    if (!isFormDirty) {
      return {
        isSaveEnabled: false,
        errorMessage: i18next.t('STOCKS.STOCKS.FORM_INVALID_NO_UPDATE'),
      };
    }

    if (
      (params.constraints || []).includes('stockReassemblyMandatory') &&
      params.inventoryType === INVENTORY_TYPE.STOCK &&
      !areAllStocksInputsFilled()
    ) {
      return {
        isSaveEnabled: false,
        errorMessage: i18next.t('STOCKS.STOCKS.FORM_INVALID_STOCK_REASSEMBLY_MANDATORY'),
      };
    }

    return {
      isSaveEnabled: true,
    };
  };

  const handleSelectedMetricChange = (value) => {
    setSelectedMetric(value);
    setSearchInput(null);
    /*
      We need to force the reset here because the useEffect calling handleSearchInput depends on searchInput
      but also uses the selectedMetric state and here we change both of them which can create problem if the state
      searchInput updates before selectedMetric because selectedMetric will not be up to date in the function
      handleSearchInput
    */
    handleSearchInput(null, value);
  };

  const generatePayloadSaveRequest = () => {
    const storeTimezone = selectedStore.timezone;

    const oldInventory = get(params, 'inventory', {});

    const stockDateWithTimezone = moment.tz(stockDate, storeTimezone);

    const inventoryList = {
      reference,
      type: params.inventoryType,
      storeId: selectedStore.id,
      total: utils.getPriceForAllStorageAreas(
        initialSPByStorageAreaIds,
        totByStorageAreaIdSPIdAndPackagingId,
        initialRecipesByStorageAreaIds,
        totByStorageAreaIdAndRecipeId,
      ),
      timestamp: oldInventory.timestamp ? oldInventory.timestamp : stockDateWithTimezone,
      inventoryDate:
        isEditingDate || isCreation
          ? stockDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
          : undefined,
      inventoryListTemplateId:
        selectedInventoryListTemplate.id !== DEFAULT_FULL_INVENTORY_TEMPLATE.id
          ? selectedInventoryListTemplate.id
          : null,
    };

    if (inventoryListId) {
      inventoryList.id = inventoryListId;
    }

    const initialStorageAreasIdsOfSelectedMetric = initialStorageAreas[selectedMetric.key].map(
      ({ id }) => id,
    );

    // SUPPLIER PRODUCTS
    let concernedStorageAreasOfSPs = initialStorageAreas[STOCK_DATA_TYPES.SUPPLIER_PRODUCT];

    const concernedStorageAreasIdsOfSPs = Object.keys(initialSPByStorageAreaIds);

    const missingStorageAreaIdsOfSPs = difference(
      concernedStorageAreasIdsOfSPs,
      initialStorageAreasIdsOfSelectedMetric,
    );

    // Fix edge case when SP storage areas list differs from recipes list
    if (missingStorageAreaIdsOfSPs.length) {
      missingStorageAreaIdsOfSPs.forEach((id) => {
        const alreadyExist = concernedStorageAreasOfSPs.some(
          (storageArea) => storageArea.id === id,
        );

        if (!alreadyExist) {
          concernedStorageAreasOfSPs.push({ id });
        }
      });
    }

    const formattedinventories = concernedStorageAreasOfSPs.reduce((acc, { id }) => {
      if (!initialSPByStorageAreaIds[id]) {
        return acc;
      }

      const inventories = utils.getInventoryPayload(initialSPByStorageAreaIds[id], {
        listId: inventoryListId,
        totByStorageAreaIdSPIdAndPackagingId: totByStorageAreaIdSPIdAndPackagingId[id],
        storageAreaId: id,
      });

      acc = [...acc, ...inventories];
      return acc;
    }, []);

    // RECIPES
    let concernedStorageAreasOfRecipes = initialStorageAreas[STOCK_DATA_TYPES.RECIPE];

    const concernedStorageAreasIdsOfRecipes = Object.keys(initialRecipesByStorageAreaIds);

    const missingStorageAreaIdsOfRecipes = difference(
      concernedStorageAreasIdsOfRecipes,
      initialStorageAreasIdsOfSelectedMetric,
    );

    // Fix edge case when SP storage area list differs from recipes list
    if (missingStorageAreaIdsOfRecipes.length) {
      missingStorageAreaIdsOfRecipes.forEach((id) => {
        const alreadyExist = concernedStorageAreasOfRecipes.some(
          (storageArea) => storageArea.id === id,
        );

        if (!alreadyExist) {
          concernedStorageAreasOfRecipes.push({ id });
        }
      });
    }

    const formattedRecipeInventories = concernedStorageAreasOfRecipes.reduce((acc, { id }) => {
      if (!initialRecipesByStorageAreaIds[id]) {
        return acc;
      }

      const recipeInventories = utils.getRecipeInventoryPayload(
        initialRecipesByStorageAreaIds[id],
        {
          inventoryListId,
          totByRecipeId: totByStorageAreaIdAndRecipeId[id],
          storageAreaId: id,
        },
      );

      acc = [...acc, ...recipeInventories];
      return acc;
    }, []);

    return {
      inventoryList,
      inventories: formattedinventories,
      recipeInventories: formattedRecipeInventories,
      triggerComputeOrderAnalytics:
        isEditingDate &&
        !moment
          .tz(stockDate, storeTimezone)
          .isSame(moment.tz(oldInventory.timestamp, storeTimezone), 'day'),
    };
  };

  const handleSave = async () => {
    const { isSaveEnabled, errorMessage } = isFormValid();

    if (!isSaveEnabled) {
      return showMessage(errorMessage, 'error');
    }

    const payload = generatePayloadSaveRequest();

    pageLoading();

    const { success, lastUpdatedAt } = await services.sendInventory(
      payload.inventoryList,
      payload.inventories,
      payload.recipeInventories,
      payload.triggerComputeOrderAnalytics,
    );

    pageLoaded();

    if (!success) {
      showMessage(
        i18next.t(
          lastUpdatedAt
            ? 'STOCKS.STOCKS.FORM_SAVE_FAILURE_DUPLICATE'
            : 'STOCKS.STOCKS.FORM_SAVE_FAILURE',
          {
            date: moment(lastUpdatedAt).format(
              DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR_HOUR_MINUTES,
            ),
          },
        ),
        'error',
        lastUpdatedAt,
      );

      return;
    }

    showMessage(i18next.t('STOCKS.STOCKS.FORM_SAVE_SUCCESSFULLY'));

    handleAppRefresh(shouldReloadApp);
    handleCloseStockForm();
  };

  const handleSetToUpdatable = () => {
    setGeneratingData(true);
    setTimeout(() => {
      setReadOnly(
        (params.inventory.isValidated && !isCorrectingInventory) ||
          (isCreation && !canCreateInventory(authorizedActions)) ||
          (!isCreation &&
            isEditionAllowed(params.inventory.createdAt) &&
            !canEditInventory(authorizedActions)) ||
          (!isCreation && !isEditionAllowed(params.inventory.createdAt) && !isCorrectingInventory),
      );
    }, 100);
  };

  const displayWarning = (type) => {
    if (type === ERROR_FORM_TYPES.ALREADY_EXISTS) {
      setInventories({
        supplierProductInventories: null,
        recipeInventories: null,
      });
      setSupplierProducts([]);
      setStoreSupplierProductMappings(null);

      props.resetStockFormValues();

      setDisplayAlreadyExistsModal(true);

      setGeneratingData(false);
    }
  };

  const handleSelectedStorageAreaChange = (selectedItem) => {
    setSelectedStorageArea(selectedItem);
    setCategoriesToUnfold([]);
  };

  const renderAlreadyExistsModal = () => (
    <div className="conf-modal-encloser">
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-grey">
          <p className="conf-modal-upper-p-grey">
            {i18next.t('STOCKS.STOCKS.FORM_ALREADY_EXISTS_HEADER')}
          </p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            <p>
              {parse(
                i18next.t('STOCKS.STOCKS.FORM_ALREADY_EXISTS_CONTENT', {
                  stockType:
                    params.inventoryType === INVENTORY_TYPE.STOCK
                      ? i18next.t('STOCKS.STOCKS.FORM_TYPE_STOCK').toLowerCase()
                      : i18next.t('STOCKS.STOCKS.FORM_TYPE_LOSS').toLowerCase(),
                }),
              )}
            </p>
          </div>
          <div className="conf-down-buttons">
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'default'}
              handleClick={() => {
                setDisplayAlreadyExistsModal(false);
              }}
              icon={'/images/inpulse/check-white-small.svg'}
              label={i18next.t('GENERAL.OKAY')}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const isThereOnlyNoneStorageArea =
    formStorageAreas[selectedMetric.key]?.length === 1 &&
    formStorageAreas[selectedMetric.key][0]?.id === STORAGE_AREA_NONE_ID;

  const isModeOffline = isUserOffline || isConnectionSlow;

  return (
    <Container>
      {warningModalParams && (
        <GenericModalContainer>
          <GenericModal
            closeGenericModal={() => setWarningModalParams(null)}
            component={warningModalParams.component}
            params={warningModalParams}
          />
        </GenericModalContainer>
      )}
      {displayAlreadyExistsModal && renderAlreadyExistsModal()}
      {displayConfirmationModal && (
        <RenderValidateModal
          closeModal={() => setDisplayConfirmationModal(false)}
          handleConfirm={() => handleCloseStockForm()}
          text={i18next.t('STOCKS.STOCKS.FORM_CONFIRMATION_MODAL_CLOSE_CONTENT')}
          title={`${i18next.t('GENERAL.LEAVE')} ?`}
        />
      )}
      <Header
        closeModal={handleClickClose}
        displayWarning={displayWarning}
        handleSave={
          isFormDirty && !generatingData && !isModeOffline && !isInventoryOutdated
            ? handleSave
            : false
        }
        inventory={params.inventory}
        inventoryCreationDate={params.inventory.createdAt}
        inventoryListTemplates={clientILTs}
        inventoryValidation={inventoryValidation}
        isCentralKitchenView={isCentralKitchenView}
        isCorrectingInventory={isCorrectingInventory}
        isCreation={!get(params, 'inventory.id', false)}
        isEditingDate={isEditingDate}
        readOnly={!isCreation || isModeOffline ? true : readOnly}
        reference={reference}
        selectedInventoryListTemplate={selectedInventoryListTemplate}
        selectedStore={selectedStore}
        setSelectedInventoryListTemplate={setSelectedTemplateById}
        setSelectedStore={setSelectedStoreById}
        setStockDate={(date) => {
          setStockDate(date);

          const isEdition =
            params.inventory &&
            params.inventory.id !== undefined &&
            params.inventory.storeId !== undefined;

          if (isEditingDate && isEdition) {
            const isDateDifferent = !moment
              .tz(date, selectedStore.timezone)
              .isSame(moment.tz(params.inventory.timestamp, selectedStore.timezone), 'day');

            setIsFormDirty(isDateDifferent);
          }
        }}
        stockDate={stockDate}
        stockOnlyToday={params.stockOnlyToday}
        stockType={params.inventoryType}
      />
      {!generatingData && !emptyStateToDisplay && (
        <>
          <FilterAndExportContainer>
            <StockFormFilters
              handleSearchInput={setSearchInput}
              isLoading={generatingData}
              searchInput={searchInput}
              selectedMetric={selectedMetric}
              selectedSuppliers={selectedSuppliers}
              setSelectedMetric={handleSelectedMetricChange}
              setSelectedSuppliers={setSelectedSuppliers}
              stockConvention={selectedStore.stockConvention}
              stockType={params.inventoryType}
              supplierChoices={supplierChoices}
              totalPrice={utils.getPriceForAllStorageAreas(
                initialSPByStorageAreaIds,
                totByStorageAreaIdSPIdAndPackagingId,
                initialRecipesByStorageAreaIds,
                totByStorageAreaIdAndRecipeId,
              )}
            />
            <StockFormActions
              currentStoreName={selectedStore.name}
              filterBy={DEFAULT_SELECTED_FILTER}
              inventoryCreationDate={params.inventory.createdAt}
              inventoryListId={inventoryListId}
              isCentralKitchenView={isCentralKitchenView}
              isCorrectingInventory={isCorrectingInventory}
              isEditingDate={isEditingDate}
              isLoading={generatingData}
              isModeOffline={isModeOffline}
              isValidated={get(params, 'inventory.isValidated', false)}
              readOnly={readOnly}
              recipes={recipesByStorageAreaIds[selectedStorageArea?.id]}
              recipesByStorageAreaIds={recipesByStorageAreaIds}
              reference={reference}
              selectedInventoryListTemplate={selectedInventoryListTemplate}
              selectedMetric={selectedMetric}
              // This condition exists because we don't want to send the 'none' storage area to this function if there's no storage areas associated to this store
              selectedStorageArea={isThereOnlyNoneStorageArea ? {} : selectedStorageArea}
              setInfoModalDownloadInventorySheet={setInfoModalDownloadInventorySheet}
              setIsCorrectingInventory={setIsCorrectingInventory}
              setIsDownloadingInventorySheet={setIsDownloadingInventorySheet}
              setIsEditingDate={setIsEditingDate}
              spByStorageAreaIds={spByStorageAreaIds}
              stockConvention={selectedStore.stockConvention}
              stockDate={stockDate}
              stockType={params.inventoryType}
              storageAreas={formStorageAreas}
              supplierProducts={spByStorageAreaIds[selectedStorageArea?.id]}
            />
          </FilterAndExportContainer>

          {
            // We don't want to display the storages area if there's none or if the only one existing is 'None'
            !!formStorageAreas[selectedMetric.key].length && !isThereOnlyNoneStorageArea && (
              <StorageAreaListing
                selectedStorageArea={selectedStorageArea}
                setSelectedStorageArea={handleSelectedStorageAreaChange}
                storageAreas={formStorageAreas[selectedMetric.key]}
              />
            )
          }
        </>
      )}
      <Content extraPaddingTop={!generatingData && !emptyStateToDisplay} ref={contentRef}>
        {generatingData && !emptyStateToDisplay && <LoadingState />}
        {emptyStateToDisplay}
        {!generatingData &&
          !emptyStateToDisplay &&
          selectedMetric.key === STOCK_DATA_TYPES.SUPPLIER_PRODUCT && (
            <StockContent
              categoriesToUnfold={categoriesToUnfold}
              groupByProperty={DEFAULT_SELECTED_FILTER}
              handleDataUpdate={props.stockFormUpdateValue}
              isSearchActive={isSearchActive}
              readOnly={readOnly}
              selectedStorageAreaId={selectedStorageArea?.id}
              setCategoriesToUnfold={setCategoriesToUnfold}
              stockType={params.inventoryType}
              supplierProducts={spByStorageAreaIds[selectedStorageArea?.id]}
              totByStorageAreaIdSPIdAndPackagingId={
                totByStorageAreaIdSPIdAndPackagingId[selectedStorageArea?.id]
              }
            />
          )}
        {!generatingData &&
          !emptyStateToDisplay &&
          selectedMetric.key === STOCK_DATA_TYPES.RECIPE && (
            <RecipeInventoryFormList
              handleQuantityUpdate={props.stockFormUpdateRecipeValue}
              hasMultipleBrands={hasMultipleBrands}
              isSearchActive={!!searchInput}
              readOnly={readOnly && !isCreation}
              recipes={recipesByStorageAreaIds[selectedStorageArea?.id]}
              selectedStorageAreaId={selectedStorageArea?.id}
              totByRecipeId={totByStorageAreaIdAndRecipeId[selectedStorageArea?.id]}
            />
          )}
      </Content>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getIsCentralMode()
    ? getCentralKitchenStores(state.baseReducer.activeStores)
    : getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  shouldReloadApp: state.baseReducer.shouldReloadApp,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/stocks/inventories/stocks',
  ),
  hasNewVersion: state.baseReducer.hasNewVersion,
  totByStorageAreaIdSPIdAndPackagingId: state.stockFormReducer.totByStorageAreaIdSPIdAndPackagingId,
  totByStorageAreaIdAndRecipeId: state.stockFormReducer.totByStorageAreaIdAndRecipeId,
  // offline
  isUserOffline: state.baseReducer.isUserOffline,
  isConnectionSlow: state.baseReducer.isConnectionSlow,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type, manualDismiss) => {
    dispatch(showConfirmationMessage(message, type, null, 3000, manualDismiss));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  stockFormUpdateValue: (supplierProductId, updatedTotByPackagingId, storageAreaId) => {
    dispatch(stockFormUpdateValue(supplierProductId, updatedTotByPackagingId, storageAreaId));
  },
  stockFormUpdateRecipeValue: (recipeId, updatedTotal, storageAreaId) => {
    dispatch(stockFormUpdateRecipeValue(recipeId, updatedTotal, storageAreaId));
  },
  resetStockFormValues: () => {
    dispatch(resetStockFormValues());
  },
  initStockFormValues: (spValues, recipesValues) => {
    dispatch(initStockFormValues(spValues, recipesValues));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StockForm);
