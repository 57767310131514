import { get, groupBy, cloneDeep, isEmpty, sortBy } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import EmptyState from '@commons/EmptyState';

import { STOCKFORM_EMPTY_STATES } from '../../utils/emptyStates';

import { SearchEmptyStateContainer, FormContainer } from './styledComponents';
import RecipeInventoryFormCategoryContent from './components/RecipeInventoryFormCategoryContent';
import RecipeInventoryFormCategorySumup from './components/RecipeInventoryFormCategorySumup';
import utils from './utils';

const FILTER_KEY = 'category';

const RecipeInventoryFormList = (props) => {
  const {
    recipes,
    handleQuantityUpdate,
    isSearchActive,
    readOnly,
    selectedStorageAreaId,
    totByRecipeId,
    hasMultipleBrands,
  } = props;

  const [categoriesToUnfold, setCategoriesToUnfold] = useState([]);
  const [categoriesToUnfoldDuringSearch, setCategoriesToUnfoldDuringSearch] = useState([]);

  const recipesGroupedByKey = groupBy(
    recipes,
    (recipe) => get(recipe, FILTER_KEY, null) || i18next.t('GENERAL.OTHER'),
  );

  useEffect(() => {
    if (isSearchActive) {
      const categories = Object.keys(recipesGroupedByKey);
      setCategoriesToUnfoldDuringSearch(categories);
      return;
    }

    setCategoriesToUnfoldDuringSearch([]);
  }, [isSearchActive]);

  const handleCategoryUnfolding = (selectedCategory) => {
    let selectedCategories = isSearchActive
      ? cloneDeep(categoriesToUnfoldDuringSearch)
      : cloneDeep(categoriesToUnfold);

    if (isSearchActive) {
      if (categoriesToUnfoldDuringSearch.includes(selectedCategory)) {
        const categoriesToDisplay = categoriesToUnfoldDuringSearch.filter(
          (category) => category !== selectedCategory,
        );

        setCategoriesToUnfoldDuringSearch(categoriesToDisplay);
        return;
      }

      selectedCategories.push(selectedCategory);
      setCategoriesToUnfoldDuringSearch(selectedCategories);

      return;
    }

    if (!!recipes && recipes.length > 100) {
      selectedCategories = [];
    }

    if (categoriesToUnfold.includes(selectedCategory)) {
      const categoriesToDisplay = categoriesToUnfold.filter(
        (category) => category !== selectedCategory,
      );

      setCategoriesToUnfold(categoriesToDisplay);
      return;
    }

    selectedCategories.push(selectedCategory);
    setCategoriesToUnfold(selectedCategories);
  };

  if (isSearchActive && isEmpty(recipes)) {
    return (
      <SearchEmptyStateContainer>
        <EmptyState
          icon={'/images/inpulse/no-results-search.svg'}
          label={i18next.t('STOCKS.RECIPES.RECIPE_STOCK_FORM_SEARCH_EMPTY_STATE_LABEL')}
        />
      </SearchEmptyStateContainer>
    );
  }

  if (isEmpty(recipesGroupedByKey)) {
    return readOnly
      ? STOCKFORM_EMPTY_STATES.NO_RECIPE_IN_INVENTORY
      : STOCKFORM_EMPTY_STATES.NO_RECIPE_ASSOCIATED_TO_STORAGE_AREA;
  }

  return (
    <>
      {Object.keys(recipesGroupedByKey)
        .sort()
        .map((key, index) => {
          const isCategoryOpen = isSearchActive
            ? categoriesToUnfoldDuringSearch.includes(key)
            : categoriesToUnfold.includes(key);

          return (
            <FormContainer displayBottomBorder={isCategoryOpen} key={index}>
              <RecipeInventoryFormCategorySumup
                handleCategoryUnfolding={handleCategoryUnfolding}
                isCategoryOpen={isCategoryOpen}
                name={key}
                total={utils.computeRecipeTotalCost(recipesGroupedByKey[key], totByRecipeId)}
              />
              {isCategoryOpen && (
                <RecipeInventoryFormCategoryContent
                  handleQuantityUpdate={handleQuantityUpdate}
                  hasMultipleBrands={hasMultipleBrands}
                  readOnly={readOnly}
                  recipes={sortBy(recipesGroupedByKey[key], 'name')}
                  selectedStorageAreaId={selectedStorageAreaId}
                  totByRecipeId={totByRecipeId}
                />
              )}
            </FormContainer>
          );
        })}
    </>
  );
};

export default RecipeInventoryFormList;
